import React from "react";

import "./App.css";
class App extends React.Component {
  openLinks = () => {
    if (document.getElementById("navbarText").style.display !== "block") {
      document.getElementById("navbarText").style.display = "block";
    } else {
      document.getElementById("navbarText").style.display = "none";
    }
  };
  render() {
    return (
      <div>
        <div id="Welcome">
          <nav class="navbar navbar-expand-lg navbar fixed-top  navbar-light bg-dark">
            <a class="navbar-brand" href="#Welcome">
              <img
                src="/images/logo-min.png"
                height="50"
                class="d-inline-block"
                alt=""
              />{" "}
            </a>
            <button
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarText"
              aria-controls="navbarText"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={this.openLinks}
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarText">
              <ul class="navbar-nav ml-auto">
                <li class="nav-item">
                  <a class="nav-link" href="#Menu">
                    Menu
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="tel:9858591010">
                    Call Now - Chennai
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="tel:6364671010">
                    Call Now - Bengaluru
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
        <div className="bannerDiv">
          <div className="overlay">
            <h3
              style={{
                fontWeight: "bolder",
              }}
            >
              <span className="orange">KCK Theenmura:</span> A Feast Like No
              Other!
            </h3>
            <p>
              Experience the heart of Kerala with every dish! Join us for a
              special meal that reflects the spirit of Kerala’s joyous
              celebrations, brought to life by Chef Regi Mathew. In our grand
              banquet spaces in Chennai and Bengaluru, relish a curated spread
              of classic and innovative dishes that pay tribute to the rich
              culinary heritage of Kerala.
            </p>
            <div>
              <h6 class="text-center">
                <span className="orange">Dates :</span> 20th Nov - 1st Dec'24
              </h6>
              <div class="topfoldRow row retainRow">
                <div class="col-4">
                  <h6 class="text-center orange">Lunch</h6>
                  <p class="text-center">
                    12:00 pm
                    <br />
                    1:15 pm
                    <br />
                    2:30 pm
                  </p>
                </div>
                <div class="col-4">
                  <h6 class="text-center orange">Dinner</h6>
                  <p class="text-center">
                    7:15 pm
                    <br />
                    8:30 pm
                    <br />
                    9:45 pm
                  </p>
                </div>
              </div>
              <h6 class="text-center">
                <span className="orange">Venue :</span> 1st Floor Banquet Hall,
                Kappa Chakka Kandhari <br />
                Chennai & Banglore
              </h6>
              <div className="cta-container">
                <a className="book-cta" href="tel:9858591010">
                  Call Now - Chennai
                </a>
                <a className="book-cta" href="tel:6364671010">
                  Call Now - Bengaluru
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="container">
          <div class="row" id="Restaurant">
            <div class="col navMenu">
              {/* <h2 class="text-center">~ Restaurant ~</h2> */}
            </div>
          </div>
          <div class="row bg-dark">
            <img
              className="mandala1"
              src="/images/mandala-1-min.png"
              alt="Image by pngtree.com"
            />
            <div class="col-md-6">
              <h3>
                <span className="orange">The Story of Theenmura:</span> A Feast
                of Legacy and Celebration
              </h3>
              <p>
                Theenmura, rooted in Kerala’s Syrian Christian cuisine, is more
                than a feast; it’s a celebration of abundance, joy, and
                togetherness. Traditionally prepared for grand occasions like
                weddings and family milestones, it showcases Kerala’s heritage
                and hospitality with dishes crafted from age-old recipes and
                seasonal freshness. Each meal embodies the love and care that
                defines Kerala’s culinary artistry.
              </p>

              <p>
                Kerala’s cuisine has evolved over centuries, enriched by Arab,
                Chinese, Portuguese, Dutch, and British influences. Traders
                introduced various culinary techniques and flavours that merged
                seamlessly with local traditions, creating the vibrant cuisine
                we cherish today.
              </p>

              <p>
                At Kappa Chakka Kandhari, we honour this legacy through
                Theenmura, reviving the grandeur of Kerala’s feasts for a new
                generation. This festival offers a taste of history and culture,
                celebrating the evolution of a cuisine that unites tradition
                with timeless flavour.
              </p>
            </div>
            <div class="col-md-6" data-aos="fade-up">
              <img class="img-fluid" src="/images/chef-cropped-min.jpg" />
            </div>
          </div>
          <div class="row bg-dark">
            <img
              className="mandala2"
              src="/images/mandala-1-min.png"
              alt="Image by pngtree.com"
            />
            <div class="col-md-6 order-md-1 order-2" data-aos="fade-up">
              <img class="img-fluid " src="images/theenmura-min.jpg" />
            </div>
            <div class="col-md-6 order-md-12 order-1">
              <h3>
                <span className="orange">A Culinary Symphony:</span> The Dishes
                of KCK’s Theenmura
              </h3>
              <p>
                At Kappa Chakka Kandhari, we bring the cherished tradition of
                Theenmura to life with a thoughtfully curated menu that blends
                nostalgia with tradition. The feast which is served course by
                course begins on a festive note with non-alcoholic wine and
                cake, reminiscent of Kerala’s celebratory traditions. Starters
                like the crispy{" "}
                <b className="orange">Chicken Cutlet with Sallas</b>, the
                indulgent{" "}
                <b className="orange">Borma Bread with Chicken Roast</b>, and
                the perfectly grilled <b className="orange">King Prawn</b>&nbsp;
                set the stage followed by the{" "}
                <b className="orange">Vinagiri Beetroot</b>.
              </p>
              <p>
                The next course is a sumptuous spread, featuring timeless
                classics such as <b className="orange">Mutton Stew</b>,{" "}
                <b className="orange">Duck Mappas</b>, and the creamy, aromatic{" "}
                <b className="orange">Fish Moilee</b>, all perfectly paired with
                Kerala staples like <b className="orange">Appam</b> and{" "}
                <b className="orange">Idiappam</b>. The feast continues with{" "}
                <b className="orange">Red Rice</b> accompanied by the{" "}
                <b className="orange">Pulissery</b>, the{" "}
                <b className="orange">Beef Fry</b>,{" "}
                <b className="orange">Thoran</b>, and traditional accompaniments
                like <b className="orange">Pickle</b> and{" "}
                <b className="orange">Pappadum</b>.
              </p>
              <p>
                The grand finale arrives with desserts that truly capture the
                essence of Kerala’s festive cuisine—indulgent{" "}
                <b className="orange">Paniyum Pazhavum</b> and the decadent{" "}
                <b className="orange">Black Halwa</b> and the traditional{" "}
                <b className="orange">Churuttu</b>. Every dish on this menu is a
                heartfelt celebration of Kerala’s rich culinary heritage,
                promising an unforgettable dining experience.
              </p>
            </div>
          </div>
          <div class="row bg-dark">
            <img
              className="mandala1"
              src="/images/mandala-1-min.png"
              alt="Image by pngtree.com"
            />
            <div class="col-md-6">
              <h3>
                <span className="orange">The Lemon Tradition:</span> A Symbol of
                Goodwill and Refreshment
              </h3>
              <p>
                At Kappa Chakka Kandhari, we honour the age-old Kerala tradition
                of giving lemons at the end of a grand meal. In earlier times,
                after significant occasions like weddings, or family milestones,
                lemons were gifted as a symbolic gesture of protection and
                well-being. It was believed that lemons could ward off evil
                spirits and bring good fortune to those who received them.
                Additionally, in the days when transportation was scarce, guests
                often walked long distances to attend such events. The lemon
                served a practical purpose too, allowing them to make a
                refreshing lemon juice once they returned home, revitalising
                their spirits after a tiring journey. By including this
                thoughtful tradition in our KCK Theenmura, we aim to connect our
                guests with the rich cultural heritage of Kerala and extend our
                warmest wishes for their well-being.
              </p>
            </div>
            <div class="col-md-6" data-aos="fade-up">
              <img class="img-fluid" src="/images/lemon-1-min.jpg" />
            </div>
          </div>
          <div class="row" id="Menu">
            <img
              className="mandala2"
              src="/images/mandala-1-min.png"
              alt="Image by pngtree.com"
              style={{
                zIndex: 1,
              }}
            />
            <div class="col navMenu">
              <h2 class="text-center">~ Menu ~</h2>
            </div>
          </div>
          <div class="row bg-dark">
            <img
              className="mandala1"
              src="/images/mandala-1-min.png"
              alt="Image by pngtree.com"
              style={{
                zIndex: 1,
              }}
            />
            <div class="col-md-4" data-aos="slide-up">
              <div class="card view zoom">
                <div class="card-body">
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item">Non Alcoholic Wine</li>
                    <li class="list-group-item">Cake</li>
                    <li class="list-group-item">Chicken Cutlet with Sallas</li>
                    <li class="list-group-item">
                      Borma Bread with Chicken Roast
                    </li>
                    <li class="list-group-item">Grilled King Prawns</li>
                    <li class="list-group-item">Vinagiri Beetroot</li>
                    <li class="list-group-item">Mutton Stew</li>
                    <li class="list-group-item">Duck Mappas</li>
                    <li class="list-group-item">Fish Moilee</li>
                    <li class="list-group-item">Idiappam/Appam</li>

                    <li class="list-group-item dk-hide">Red rice</li>
                    <li class="list-group-item dk-hide">Pulissery</li>
                    <li class="list-group-item dk-hide">Beef Fry</li>
                    <li class="list-group-item dk-hide">Meen Vattichathu</li>
                    <li class="list-group-item dk-hide">Thoran</li>
                    <li class="list-group-item dk-hide">Pappadum</li>
                    <li class="list-group-item dk-hide">Pickle</li>
                    <li class="list-group-item dk-hide">Paniyum Pazhavum</li>
                    <li class="list-group-item dk-hide">Black Halwa</li>
                    <li class="list-group-item dk-hide">Churuttu</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-hide" data-aos="slide-up">
              <div class="card">
                <div class="card-body">
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item">Red rice</li>
                    <li class="list-group-item">Pulissery</li>
                    <li class="list-group-item">Beef Fry</li>
                    <li class="list-group-item">Meen Vattichathu</li>
                    <li class="list-group-item">Thoran</li>
                    <li class="list-group-item">Pappadum</li>
                    <li class="list-group-item">Pickle</li>
                    <li class="list-group-item">Paniyum Pazhavum</li>
                    <li class="list-group-item">Black Halwa</li>
                    <li class="list-group-item">Churuttu</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="row" id="OurLocation">
            <div class="col navMenu">
              <h2 class="text-center">~ Our Location ~</h2>
            </div>
          </div>
          <div class="row">
            <a
              href="https://www.google.com/maps?q=Kappa+Chakka+Kandhari,+10,+Haddows+Rd,+opp.+Shastri+Bhavan,+Thousand+Lights+West,+Nungambakkam,+Chennai,+Tamil+Nadu+600006&ftid=0x3a5266694c0435c1:0xedf7096ca0bc60d4&entry=gps&lucs=,94244542,94246479,94242601,94224825,94227247,94227248,47071704,47069508,94218641,94228348,94233073,94203019,47084304,94208458,94208447&g_ep=CAISDTYuMTQxLjAuOTE2NjAYACCenQoqhwEsOTQyNDQ1NDIsOTQyNDY0NzksOTQyNDI2MDEsOTQyMjQ4MjUsOTQyMjcyNDcsOTQyMjcyNDgsNDcwNzE3MDQsNDcwNjk1MDgsOTQyMTg2NDEsOTQyMjgzNDgsOTQyMzMwNzMsOTQyMDMwMTksNDcwODQzMDQsOTQyMDg0NTgsOTQyMDg0NDdCAklO&g_st=com.google.maps.preview.copy"
              target="_blank"
              class="col-md-4 text-center location-link"
            >
              <p>
                Chennai: 10,Haddows road,
                <br /> Chennai-600006
              </p>
              <p>Ph.no: 98585 91010</p>
            </a>
            <a
              target="_blank"
              href="https://www.google.com/maps?q=Kappa+Chakka+Kandhari,+438,+18th+Main+Rd,+6th+Block,+Koramangala,+Bengaluru,+Karnataka+560095&ftid=0x3bae15dbf25e4f4f:0xea29e2bb350ec7d5&entry=gps&lucs=,94244542,94246479,94242601,94224825,94227247,94227248,47071704,47069508,94218641,94228348,94233073,94203019,47084304,94208458,94208447&g_ep=CAISDTYuMTQxLjAuOTE2NjAYACCenQoqhwEsOTQyNDQ1NDIsOTQyNDY0NzksOTQyNDI2MDEsOTQyMjQ4MjUsOTQyMjcyNDcsOTQyMjcyNDgsNDcwNzE3MDQsNDcwNjk1MDgsOTQyMTg2NDEsOTQyMjgzNDgsOTQyMzMwNzMsOTQyMDMwMTksNDcwODQzMDQsOTQyMDg0NTgsOTQyMDg0NDdCAklO&g_st=com.google.maps.preview.copy"
              class="col-md-4 text-center location-link"
            >
              <p>
                Bengaluru: 6th Block, Koramangala,
                <br /> Bengaluru-560095
              </p>
              <p>Ph.no: 63646 71010</p>
            </a>
          </div>

          <div class="row footer bg-dark">
            <div class="col">
              <p class="text-center">
                <a
                  class="social-icon"
                  href="https://www.instagram.com/kckfoods"
                  style={{
                    color: "white",
                  }}
                >
                  Follow us on &nbsp;
                  <i class="fab fa-instagram"></i>
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default App;
